"use client";
import useTrack from "@/lib/tracking";
import { useInView } from "framer-motion";
import { useEffect, useRef } from "react";

export default function ViewTracker({
  id,
  name,
}: {
  id?: string | number;
  name?: string | number;
}) {
  const { track } = useTrack();

  const ref = useRef(null);
  const isInView = useInView(ref, {
    amount: 0.3,
    once: true,
  });

  useEffect(() => {
    if (isInView) track(`View - Section`, { id, name });
  }, [isInView, id, name, track]);

  return <span ref={ref} className="absolute -z-50 size-full opacity-0" />;
}
