"use client";
import { useIsClient } from "@/hooks/useIsClient";
import { useState, type PropsWithChildren } from "react";
import { PopupModal } from "react-calendly";
import Button, { type ButtonProps } from "@/atoms/Button";
import { Button as ButtonBorder } from "@/components/ui/moving-border";
import { useLocale } from "next-intl";
import { useSearchParams } from "next/navigation";
import { ArrowRightIcon } from "lucide-react";
import { cn } from "@/lib/utils";

export type ButtonCalendlyProps = PropsWithChildren<
  ButtonProps & {
    url?: string;
    animate?: boolean;
    arrow?: boolean;
    all?: boolean;
    movingBorder?: boolean;
  }
>;

export default function ButtonCalendly({
  url,
  children,
  animate = true,
  arrow = true,
  all,
  movingBorder = false,
  ...props
}: ButtonCalendlyProps) {
  const [open, setOpen] = useState(false);
  const isClient = useIsClient();
  const searchParams = useSearchParams();
  const locale = useLocale();

  const link =
    url || locale === "en" // Perfect link with infos if exists
      ? "https://calendly.com/romain-matt-vd/1st-consultation-en/" // ?first_name=TONTON&last_name=TATAT&email=r.piveteau@outlook.com
      : "https://calendly.com/romain-matt-vd/1ere-consultation/"; // ?first_name=TONTON&last_name=TATAT&email=r.piveteau@outlook.com

  return (
    <>
      {movingBorder ? (
        <ButtonBorder {...props} onClick={() => setOpen(true)}>
          {children}
          {arrow && (
            <ArrowRightIcon
              size={20}
              className={cn("ml-2", {
                "animate-horizontal-bounce": animate,
              })}
            />
          )}
        </ButtonBorder>
      ) : (
        <Button {...props} onClick={() => setOpen(true)}>
          {children}
          {arrow && (
            <ArrowRightIcon
              size={20}
              className={cn("ml-2", {
                "animate-horizontal-bounce": animate,
              })}
            />
          )}
        </Button>
      )}

      {isClient && (
        <PopupModal
          open={open}
          url={
            all
              ? "https://calendly.com/romain-matt-vd"
              : improveLink(link, searchParams)
          }
          onModalClose={() => setOpen(false)}
          rootElement={document.body}
        />
      )}
    </>
  );
}

function improveLink(link: string, params?: URLSearchParams) {
  if (!params) return link;

  let op = "?";
  const first_name = params.get("first_name"),
    last_name = params.get("last_name"),
    email = params.get("email"),
    date = params.get("date");

  if (first_name) {
    link += `${op}first_name=${first_name}`;
    op = "&";
  }
  if (last_name) {
    link += `${op}last_name=${last_name}`;
    op = "&";
  }
  if (email) {
    link += `${op}email=${email}`;
    op = "&";
  }
  if (date) {
    link += `${op}date=${date}`;
    op = "&";
  }

  return link;
}
